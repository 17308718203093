import React, { useState } from 'react';
import styled from 'styled-components';

import left from './images/arrow-left.svg';
import right from './images/arrow-right.svg';

import sky from './images/FlyingMonkeys_DayNight.jpg';
import bg from './images/BeerWorld_BG_Buildings.png';
import eighth from './images/eighth-grass.png';

import skySeltzer from './images/seltzer-world.png';

import GlobeCan from './globe-can';

const WheelSection = styled.section`
	position: relative;
	width: 100vw;
	/*height: 56.25vw;*/
	height: 60vw;
	overflow: hidden;
	background-color: lightblue;
	img {
		max-width: unset;
	}
	.foretext {
		color: white;
		position: absolute;
		z-index: 100;
		width: 100%;
		text-align: center;
		font-family: sans-serif;
		font-size: 2rem;
		top: 40vw;
	}

	.name-wrap {
		position: absolute;
		bottom: 5vw;
		width: 100%;
		.beer-name {
			text-align: center;
			margin: 0 auto;
			max-width: 500px;
			font-size: 3rem;
			color: white;
			text-shadow: 2px 2px 2px #000;
		}
		.beer-subname {
			text-align: center;
			margin: 0 auto;
			max-width: 500px;
			font-size: 1.5rem;
			color: white;
			text-shadow: 2px 2px 2px #000;
		}
	}

	@media (max-width: 999px) {
		height: 150vw;
		.name-wrap {
			bottom: 54px;

			.beer-name {
				max-width: 66%;
				bottom: 54px;
				font-size: 1.5rem;
			}
		}
	}
`;

const BgImg = styled.img`
	width: 100%;
	position: relative;
	top: 20vw;
	width: 100%;
	@media (max-width: 999px) {
		top: 53vw;
	}
`;

const SkyImg = styled.img`
  position: absolute;
  width: 350vw;
  top: -10vw;
  left: -125vw;
  animation: rotation 120s infinite linear;
  /*transform: rotate(${(p) => p.rotateIndex * -7.5}deg);*/
  transition: transform ease-out 400ms;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
	@media (max-width: 999px) {
		width: 700vw;
		left: -300vw;
	}
`;

const GrassWrap = styled.div`
	position: absolute;
	width: 300vw;
	height: 300vw;
	top: 35vw;
	left: -100vw;
	transform: rotate(${(p) => p.rotateIndex * -15}deg);
	transition: transform ease-in-out 500ms;
	img {
		width: 50%;
		height: 50%;
		left: 50%;
		position: absolute;
		transform-origin: bottom left;
		&:nth-child(2) {
			transform: rotate(45deg);
		}
		&:nth-child(3) {
			transform: rotate(90deg);
		}
		&:nth-child(4) {
			transform: rotate(135deg);
		}
		&:nth-child(5) {
			transform: rotate(180deg);
		}
		&:nth-child(6) {
			transform: rotate(225deg);
		}
		&:nth-child(7) {
			transform: rotate(270deg);
		}
		&:nth-child(8) {
			transform: rotate(315deg);
		}
	}
	@media (max-width: 999px) {
		width: 600vw;
		height: 600vw;
		top: 70vw;
		left: -250vw;
	}
`;

const NavButton = styled.button`
	width: 96px;
	height: 48px;
	position: absolute;
	top: 35%;
	cursor: pointer;
	outline: 0;
	transition: transform ease-out 200ms;
	z-index: 10;
	background-color: transparent;
	border: none;
	&:hover {
		transform: scale(1.1);
	}
	@media (max-width: 999px) {
		top: 50%;
		width: 64px;
		height: 32px;
	}
`;

const PrevButton = styled(NavButton)`
	left: 6rem;
	background-image: url(${left});
	@media (max-width: 999px) {
		left: 0.5rem;
	}
`;

const NextButton = styled(NavButton)`
	right: 6rem;
	background-image: url(${right});
	@media (max-width: 999px) {
		right: 0.5rem;
	}
`;

const getPrevFocusIndex = (currentIndex, howManyBack, length) => {
	return (currentIndex + length - howManyBack) % length;
};

const getNextFocusIndex = (currentIndex, howManyForward, length) => {
	return (currentIndex + howManyForward) % length;
};

const getRotateClass = (focusIndex, index, length) => {
	if (focusIndex === index) {
		return 'middle';
	} else if (getPrevFocusIndex(focusIndex, 2, length) === index) {
		return 'off-left';
	} else if (getPrevFocusIndex(focusIndex, 1, length) === index) {
		return 'on-left';
	} else if (getNextFocusIndex(focusIndex, 1, length) === index) {
		return 'on-right';
	} else {
		return 'off-right';
	}
};

/*<GlobeImg src={globe} alt="globe" rotateIndex={rotateIndex} />*/

export default ({ beers, isSeltzer }) => {
	const [focusIndex, setFocusIndex] = useState(0);
	const [rotateIndex, setRotateIndex] = useState(0);

	return (
		<WheelSection>
			<SkyImg src={isSeltzer ? skySeltzer : sky} alt="globe" rotateIndex={rotateIndex} />
			{!isSeltzer && <BgImg src={bg} />}
			<GrassWrap rotateIndex={rotateIndex}>
			{!isSeltzer && 
				<>
					<img src={eighth} alt="grass" />
					<img src={eighth} alt="grass" />
					<img src={eighth} alt="grass" />
					<img src={eighth} alt="grass" />
					<img src={eighth} alt="grass" />
					<img src={eighth} alt="grass" />
					<img src={eighth} alt="grass" />
					<img src={eighth} alt="grass" />
				</>
			}
			</GrassWrap>
			<PrevButton
				onClick={() => {
					setFocusIndex(getPrevFocusIndex(focusIndex, 1, beers.length));
					setRotateIndex(rotateIndex - 1);
				}}
			/>
			<NextButton
				onClick={() => {
					setFocusIndex(getNextFocusIndex(focusIndex, 1, beers.length));
					setRotateIndex(rotateIndex + 1);
				}}
			/>
			<div>
				{beers.map((e, i) => (
					<GlobeCan
						focusIndex={focusIndex}
						index={i}
						color={e.color}
						rotateClass={getRotateClass(focusIndex, i, beers.length)}
						can={e.can}
						foreground={e.bg}
						key={i}
						slug={e.slug}
					/>
				))}
			</div>
			<div className="name-wrap">
				<h2 className="beer-name">{beers[focusIndex].name}</h2>
				<h3 className="beer-subname">{beers[focusIndex].type}</h3>
			</div>
		</WheelSection>
	);
};
