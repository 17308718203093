import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
	width: 15vw;
	height: 30vw;
	position: absolute;
	left: calc(50vw - 7.5vw);
	top: 20vw;
	transition: transform ease-in-out 500ms, opacity ease-in-out 500ms;
	transform-origin: 50% 520%;
	&.off-left {
		transform: rotate(-30deg);
		opacity: 0;
	}
	&.on-left {
		transform: rotate(-15deg);
		opacity: 1;
	}
	&.middle {
		transform: rotate(0deg) translateY(-5vw);
		opacity: 1;
	}
	&.on-right {
		transform: rotate(15deg);
		opacity: 1;
	}
	&.off-right {
		transform: rotate(30deg);
		opacity: 0;
	}
	@media (max-width: 999px) {
		width: 30vw;
		height: 60vw;
		left: calc(50vw - 15vw);
		top: 50vw;
	}
`;

const Inner = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${(p) => p.color};
	background-size: contain;
	transition: transform 500ms ease-in-out, top 500ms ease-in-out;
	cursor: pointer;
	img {
		width: 100%;
		height: 100%;
	}
	${(p) => {
		if (p.focusIndex === p.index) {
			return `transform: scale(1);`;
		} else {
			return `transform: scale(0.5);`;
		}
	}}
	&:hover {
		${(p) => {
			if (p.focusIndex === p.index) {
				return `transform: scale(1.2);`;
			} else {
				return `transform: scale(0.6);`;
			}
		}}
	}

	@media (max-width: 999px) {
		${(p) => {
			if (p.focusIndex === p.index) {
				return `transform: scale(1.5);`;
			} else {
				return `transform: scale(0.6);`;
			}
		}}
	}
`;

const Front = styled.img`
	width: 300%;
	height: 150%;
	transition: transform ease-in-out 500ms, top ease-in-out 500ms,
		opacity ease-in-out 500ms;
	position: absolute;
	left: -100%;

	${(p) => {
		if (p.focusIndex === p.index) {
			return `
				transform: scale(1);
				top: -4vw;
				opacity: 1;
			`;
		} else {
			return `
				transform: scale(0.5);
				top: -3.75vw;
				opacity: 0;
			`;
		}
	}};

	@media (max-width: 999px) {
		${(p) => {
			if (p.focusIndex === p.index) {
				return `
				transform: scale(1);
				top: -10vw;
				opacity: 1;
			`;
			} else {
				return `
				transform: scale(0.5);
				top: -3.75vw;
				opacity: 0;
			`;
			}
		}};
	}
`;

export default ({
	focusIndex,
	index,
	color,
	rotateClass,
	can,
	foreground,
	slug,
}) => {
	return (
		<Wrap focusIndex={focusIndex} index={index} className={rotateClass}>
			<a href={`${slug}`}>
				<Front
					focusIndex={focusIndex}
					index={index}
					color={color}
					src={foreground}
				/>
				<Inner focusIndex={focusIndex} index={index} color={color} can={can}>
					<img src={can} alt="" />
				</Inner>
			</a>
		</Wrap>
	);
};
